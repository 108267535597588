import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import Layout from "src/components/layout";
import SEO from "src/components/seo";

import EnablingCreators from "src/sections/forCreators/EnablingCreators";

import { ReactComponent as CreatorsSVG } from "svg/creatorsSideways.svg";
import media from "src/styles/media";

const ForCreatorsPage = ({ data }) => {
  return (
    <Layout landingPage>
      <SEO title="Creators" />
      <Creators />
      <EnablingCreators data={data} />
    </Layout>
  );
};

const Creators = styled(CreatorsSVG)`
  position: absolute;
  left: 0;
  top: 0;
  width: 6.5vw;
  ${media.tablet} {
    display: none;
  }
`;
export const pageQuery = graphql`
  query {
    sneaker: file(relativePath: { eq: "sneaker.png" }) {
      ...fluidImage
    }
    astro1: file(relativePath: { eq: "astro1.png" }) {
      ...fluidImage
    }
    lineGrid: file(relativePath: { eq: "lineGrid.png" }) {
      ...fluidImage
    }
    tokens: file(relativePath: { eq: "transparentTokens.png" }) {
      ...fluidImage
    }
  }
`;
export default ForCreatorsPage;
